import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { searchCustomer } from 'actions/CustomerActions';
import {
  searchMasterList,
  searchTestGroupList,
  createSample,
  getSampleById,
  updateSample,
  searchReference,
  getPriceListByMasterAndCustomer,
} from 'actions/LaboratoryActions';
import { getUserList } from 'actions/UserAction';
import { SearchDialog } from 'components/SearchDialog';
import { ICustomer, IMaster, IReference, ITestGroup, IUser } from 'exactt-types/lib';
import { useFormik } from 'formik';
import useQuery from 'utils/getQueryParams';
import moment from 'moment';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';
import { handleChangeWhiteSpace } from 'utils/removeWhiteSpaces';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { IPersistSamplePharmaProps, IFormikPersistSample } from './IPersistSamplePharma';
import { CreateSampleSchemaPharma } from 'FormSchema/CreateSampleSchemaPharma';

const animatedComponents = makeAnimated();

export default function _PersistSamplePharma(props: IPersistSamplePharmaProps) {
  const navigate = useNavigate();
  let query = useQuery();
  let sampleId = query.get('id');
  const laboratoryId = props.laboratory?.details?._id || '';

  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [customerSearchModal, setCustomerSearchModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const [referenceSearchQuery, setReferenceSearchQuery] = useState('');
  const [referenceSearchModal, setReferenceSearchModal] = useState(false);
  const [selectedReference, setSelectedReference] = useState<IReference | null>(null);
  const [masterSearchQuery, setMasterSearchQuery] = useState('');
  const [masterSearchModal, setMasterSearchModal] = useState(false);
  const [selectedMaster, setSelectedMaster] = useState<IMaster | null>(null);
  const [testGroupSearchQuery, setTestGroupSearchQuery] = useState('');
  const [testGroupSearchModal, setTestGroupSearchModal] = useState(false);
  const [selectedTestGroup, setSelectedTestGroup] = useState<ITestGroup | null>(null);

  const formik: IFormikPersistSample = useFormik({
    initialValues: CreateSampleSchemaPharma.initialValues,
    validationSchema: CreateSampleSchemaPharma.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const parameters = selectedMaster?.parameters || [];
        const sortedParameters: any[] = [];
        parameters.map((i) => {
          const found = values.parameters.find((j: any) => j.name === i.name);
          if (found) sortedParameters.push(found);
        });
        if (sampleId) {
          // values.parameters should be sorted according to master parameters

          await props.updateSample({
            ...values,
            laboratoryId,
            _id: sampleId,
            master: selectedMaster?._id || '',
            formType: 'WATER',
            retainDate: new Date(),
            retainBy: '',
            sampleReceivedBy: '',
            parameters: sortedParameters as any,
            discordedOn: new Date(),
            discordedBy: '',
            dispatchMode: '',
          });
          navigate('/laboratory/samples/sampleDetails?id=' + sampleId);
        } else {
          await props.createSample({
            ...values,
            laboratoryId,
            master: selectedMaster?._id || '',
            formType: 'PHARMA',
            retainDate: new Date(),
            retainBy: '',
            parameters: sortedParameters as any,
            sampleReceivedBy: '',
            discordedOn: new Date(),
            discordedBy: '',
            dispatchMode: '',
          });
          navigate('/laboratory/samples');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });
  useEffect(() => {
    if (props.sampleDetails && sampleId) {
      setSelectedCustomer(props.sampleDetails.customer as ICustomer);
      setSelectedTestGroup(props.sampleDetails.testGroup as ITestGroup);
      setSelectedMaster(props.sampleDetails.master as IMaster);

      formik.setValues({
        // !dates are set to new Date() because of the error in date format
        testAsPer: props.sampleDetails.testAsPer || '',
        remark1: props.sampleDetails.remark1 || '',
        specialInstructions: props.sampleDetails.specialInstructions || '',
        sampleDescription: props.sampleDetails.sampleDescription || '',
        instructionForUsed: props.sampleDetails.instructionForUsed || '',
        countryOfOrigin: props.sampleDetails.countryOfOrigin || '',
        purposeOfMonitoring: props.sampleDetails.purposeOfMonitoring || '',
        emissionSourceMonitor: props.sampleDetails.emissionSourceMonitor || '',
        productManufacturedSamplingTime: props.sampleDetails.productManufacturedSamplingTime || '',
        nameOfPlantSection: props.sampleDetails.nameOfPlantSection || '',
        controlMeasures: props.sampleDetails.controlMeasures || '',
        authorizedPersonAddress: props.sampleDetails.authorizedPersonAddress || '',
        recoveryOfMaterial: props.sampleDetails.recoveryOfMaterial || '',
        sealOnContainerBISSeal: props.sampleDetails.sealOnContainerBISSeal || '',
        generalSensory: props.sampleDetails.generalSensory || '',
        CHSRegnNo: props.sampleDetails.CHSRegnNo || '',
        fugitiveEmission: props.sampleDetails.fugitiveEmission || '',
        CHACardHolder: props.sampleDetails.CHACardHolder || '',
        stackTemp: props.sampleDetails.stackTemp || '',
        dateAndPlaceOfCollectionOfSample: props.sampleDetails.dateAndPlaceOfCollectionOfSample || '',
        ambientTemperature: props.sampleDetails.ambientTemperature || '',
        batchNo: props.sampleDetails.batchNo || '',
        testMethod: props.sampleDetails.testMethod || '',
        nameAndAddressOfImporter: props.sampleDetails.nameAndAddressOfImporter || '',
        refNo: props.sampleDetails.refNo || '',
        locationOfAnalysis: props.sampleDetails.locationOfAnalysis || '',
        sourceOfWater: props.sampleDetails.sourceOfWater || '',
        statement: props.sampleDetails.statement || '',
        batchSize: props.sampleDetails.batchSize || '',
        brandName: props.sampleDetails.brandName || '',
        authorizedSignature1: (props.sampleDetails.authorizedSignature1 as IUser)?._id || '',
        collectionBy: (props.sampleDetails.collectionBy as any) || '',
        collectionDate: props.sampleDetails.collectionDate || new Date(),
        description: props.sampleDetails.description || '',
        discount: props.sampleDetails.discount || 0,
        dueDate: props.sampleDetails.dueDate || new Date(),
        labDueDate: props.sampleDetails.labDueDate || new Date(),
        drugLicNo: props.sampleDetails.drugLicNo || '',
        envCondition: props.sampleDetails.envCondition || '',
        sampleReceivedMode: props.sampleDetails.sampleReceivedMode || '',
        otherInformation: props.sampleDetails.otherInformation || '',
        expDate: props.sampleDetails.expDate || new Date(),
        gst: props.sampleDetails.gst || 0,
        hod: (props.sampleDetails.hod as IUser)?._id || '',
        igst: props.sampleDetails.igst || false,
        mfgDate: props.sampleDetails.mfgDate || new Date(),
        name: props.sampleDetails.name,
        paidAmount: props.sampleDetails.paidAmount || 0,
        // @ts-expect-error
        parameters: props.sampleDetails.parameters?.map((i) => ({ ...i, department: i.department._id })),
        rate: props.sampleDetails.rate || 0,
        sac: props.sampleDetails.sac || '',
        sampleCode: props.sampleDetails.sampleCode || '',
        sampleCondition: props.sampleDetails.sampleCondition || '',
        samplePacking: props.sampleDetails.samplePacking || '',
        sampleQty: props.sampleDetails.sampleQty || '',
        samplingMethod: props.sampleDetails.samplingMethod || '',
        servingSize: props.sampleDetails.servingSize || '',
        supplier: props.sampleDetails.supplier || '',
        testGroup: (props.sampleDetails.testGroup as any)?._id || '' || undefined,
        type: props.sampleDetails.type || '',
        additionalFields: props.sampleDetails.additionalFields || {},
        customer: (props.sampleDetails.customer as any)._id || '',
        reference: (props.sampleDetails.reference as any)?._id || '',
        genericName: props.sampleDetails.genericName,
        manufacture: props.sampleDetails.manufacture || '',
        marking: props.sampleDetails.marking || '',
        paymentDueDate: props.sampleDetails.paymentDueDate || new Date(),
        reviewedBy: (props.sampleDetails.reviewedBy as any)?._id || '',
        authorizedSignature2: (props.sampleDetails.authorizedSignature2 as any)?._id || '' || undefined,
        labelClaim: props.sampleDetails.labelClaim || '',
      });
    }
    // eslint-disable-next-line
  }, [props.sampleDetails]);

  const promiseCustomerOptions = async (inputValue: string) => {
    const list = await props.searchCustomer(laboratoryId, { page: 1, size: 10, query: inputValue });

    return list.data.customers.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  const promiseReferenceOptions = async (inputValue: string) => {
    const list = await props.searchReference(laboratoryId, { page: 1, size: 10, query: inputValue });
    return list.data.references.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  const promiseTestGroupOptions = async (inputValue: string) => {
    const list = await props.searchTestGroupList(laboratoryId, { page: 1, size: 10, query: inputValue });
    return list.data.testGroups.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  useEffect(() => {
    props.searchCustomer(laboratoryId, { page: 1, size: 10, query: customerSearchQuery });
    // eslint-disable-next-line
  }, [customerSearchQuery]);

  useEffect(() => {
    props.searchMasterList(laboratoryId, { page: 1, size: 10, query: masterSearchQuery });
    // eslint-disable-next-line
  }, [masterSearchQuery]);

  useEffect(() => {
    props.searchTestGroupList(laboratoryId, { page: 1, size: 10, query: testGroupSearchQuery });
    // eslint-disable-next-line
  }, [testGroupSearchQuery]);

  useEffect(() => {
    props.searchReference(laboratoryId, { page: 1, size: 10, query: referenceSearchQuery });
    // eslint-disable-next-line
  }, [referenceSearchQuery]);

  useEffect(() => {
    if (selectedMaster && selectedCustomer) {
      props.getPriceListByMasterAndCustomer(laboratoryId, selectedMaster._id || '', selectedCustomer._id || '');
    }
  }, [selectedMaster, selectedCustomer]);

  useEffect(() => {
    if (
      props.priceList &&
      props.priceList.customer === selectedCustomer?._id &&
      props.priceList.master === selectedMaster?._id
    ) {
      // check selected parameters and set rat
      const parameters = formik.values.parameters;
      let rate = 0;

      const selectedGroups: string[] = [];

      props.priceList.parameters.map((i) => {
        const found: any = parameters.find((j: any) => j.name === i.name);

        let addParameter = true;

        if (found?.resultGroup) {
          const findGroupPrice = props.priceList?.parameters?.find((j) => j.name === found.resultGroup);
          if (findGroupPrice?.price) {
            addParameter = false;
            if (!selectedGroups.includes(found.resultGroup)) {
              rate += findGroupPrice.price;
              selectedGroups.push(found.resultGroup);
            }
          }
        }

        if (found && addParameter) {
          rate += i.price;
        }
      });
      formik.setFieldValue('rate', rate);
    } else {
      formik.setFieldValue('rate', 0);
    }
  }, [props.priceList, formik.values.parameters]);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Samples',
      onClick: () => navigate('/laboratory/samples'),
    },
  ];
  const additionalFields: any = formik.values.additionalFields;

  const handleChange = (event: any) => {
    const { value } = event.target;
    const length = event.target.value.length;
    if (value[length - 1] === ' ' && value[length - 2] === ' ') {
      event.target.value = handleChangeWhiteSpace(value);
    }
    formik.handleChange(event);
  };

  const handleSelectAllParameter = () => {
    if (selectedMaster) {
      const parameters = selectedMaster?.parameters;
      if (parameters?.length) {
        formik.setFieldValue('parameters', parameters);
      }
    } else {
      if (formik.values.parameters.length === 0) {
        formik.setFieldError('parameters', 'Please select a master');
      }
    }
  };
  const gstAmount = formik.values.rate * (formik.values.gst / 100);
  const totalRate = formik.values.rate + gstAmount;
  const discountAmount = totalRate * (formik.values.discount / 100);
  const total = totalRate - discountAmount;
  const remainingTotal = total - formik.values.paidAmount;

  const parametersForLabelClaim = (formik.values.parameters || [])?.filter(
    (i: any) => i.validations?.type === 'percentageLC'
  );

  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Sample Entry (Pharma)" />
      <Box p={2} pt={10}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                  Basic Details
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Booking Party">
                    <AsyncSelect
                      loadOptions={promiseCustomerOptions}
                      value={{ value: selectedCustomer?._id, label: selectedCustomer?.name }}
                      defaultOptions={props.searchCustomerList.map((i) => ({
                        label: i.name,
                        value: i._id,
                      }))}
                      onChange={(newValue: any) => {
                        const findCustomer = props.searchCustomerList.find((i) => i._id === newValue?.value);
                        if (findCustomer) setSelectedCustomer(findCustomer);
                        formik.setFieldValue('customer', newValue?.value || '');
                      }}
                    />
                  </CustomFieldWithLabel>
                  {formik.touched.customer && formik.errors.customer}
                </Grid>
              </Grid>

              <Grid item xs={12} gap={1}>
                <Grid item xs={12}>
                  {/* <CustomFieldWithLabel width={120} label="Reference by">
                    <AsyncSelect
                      loadOptions={promiseReferenceOptions}
                      value={{ value: selectedReference?._id, label: selectedReference?.name }}
                      defaultOptions={(props.referenceList || []).map((i) => ({
                        label: i.name,
                        value: i._id,
                      }))}
                      onChange={(newValue: any) => {
                        const findRef = (props.referenceList || []).find((i) => i._id === newValue?.value);
                        if (findRef) setSelectedReference(findRef);
                        formik.setFieldValue('reference', newValue?.value || '');
                      }}
                    />
                  </CustomFieldWithLabel>
                  {formik.touched.reference && formik.errors.reference} */}
                </Grid>
              </Grid>

              <Grid item xs={12} gap={1} style={{ display: 'flex' }}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Test Group">
                    <AsyncSelect
                      loadOptions={promiseTestGroupOptions}
                      value={{ value: selectedTestGroup?._id, label: selectedTestGroup?.name }}
                      defaultOptions={(props.testGroupsList || []).map((i) => ({
                        label: i.name,
                        value: i._id,
                      }))}
                      onChange={(newValue: any) => {
                        const testGroup = (props.testGroupsList || []).find((i) => i._id === newValue?.value);
                        if (testGroup) setSelectedTestGroup(testGroup);
                        formik.setFieldValue('testGroup', newValue?.value || '');
                      }}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel width={120} label="Receipt date">
                  <LocalizationProvider
                    dateAdapter={DateAdapter}
                    value={formik.values.collectionDate}
                    error={formik.touched.collectionDate && formik.errors.collectionDate ? true : false}
                    helperText={formik.touched.collectionDate && formik.errors.collectionDate}
                  >
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      // disabled={true}
                      onChange={(value) => {
                        formik.setFieldValue('collectionDate', moment(value).toDate());
                        formik.setFieldTouched('collectionDate', true);
                      }}
                      value={formik.values.collectionDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="none"
                          error={formik.touched.collectionDate && formik.errors.collectionDate ? true : false}
                          helperText={formik.touched.collectionDate && formik.errors.collectionDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel width={120} label="Due date">
                  <LocalizationProvider
                    dateAdapter={DateAdapter}
                    value={formik.values.dueDate}
                    error={formik.touched.dueDate && formik.errors.dueDate ? true : false}
                    helperText={formik.touched.dueDate && formik.errors.dueDate}
                  >
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      onChange={(value) => {
                        formik.setFieldValue('dueDate', moment(value).toDate());
                        formik.setFieldTouched('dueDate', true);
                      }}
                      value={formik.values.dueDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          margin="none"
                          fullWidth
                          error={formik.touched.dueDate && formik.errors.dueDate ? true : false}
                          helperText={formik.touched.dueDate && formik.errors.dueDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel width={120} label="Lab due Date">
                  <LocalizationProvider
                    dateAdapter={DateAdapter}
                    value={formik.values.labDueDate}
                    error={formik.touched.labDueDate && formik.errors.labDueDate ? true : false}
                    helperText={formik.touched.labDueDate && formik.errors.labDueDate}
                  >
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      onChange={(value) => {
                        formik.setFieldValue('labDueDate', moment(value).toDate());
                        formik.setFieldTouched('labDueDate', true);
                      }}
                      value={formik.values.labDueDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin="none"
                          error={formik.touched.labDueDate && formik.errors.labDueDate ? true : false}
                          helperText={formik.touched.labDueDate && formik.errors.labDueDate}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase', marginTop: '1%' }} fontSize={14}>
                  Sample Details
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Sample Name">
                    <TextField
                      fullWidth
                      required
                      disabled={!!sampleId}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setMasterSearchModal(true);
                        }
                      }}
                      value={formik.values.name || ''}
                      error={formik.touched.name && formik.errors.name ? true : false}
                      helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      onChange={handleChange}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Generic Name">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      value={formik.values.genericName || ''}
                      error={formik.touched.genericName && formik.errors.genericName ? true : false}
                      helperText={formik.touched.genericName && formik.errors.genericName}
                      onChange={handleChange}
                      name={'genericName'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Sample Description">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      value={formik.values.sampleDescription || ''}
                      error={formik.touched.sampleDescription && formik.errors.sampleDescription ? true : false}
                      helperText={formik.touched.sampleDescription && formik.errors.sampleDescription}
                      onChange={handleChange}
                      name={'sampleDescription'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Ref. No.">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      onChange={handleChange}
                      value={formik.values.sampleCode || ''}
                      error={formik.touched.sampleCode && formik.errors.sampleCode ? true : false}
                      helperText={formik.touched.sampleCode && formik.errors.sampleCode}
                      name={'sampleCode'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              {/* <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Brand Name">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      onChange={handleChange}
                      value={formik.values.brandName || ''}
                      error={formik.touched.brandName && formik.errors.brandName ? true : false}
                      helperText={formik.touched.brandName && formik.errors.brandName}
                      name={'brandName'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid> */}

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Manufacture Name">
                    <TextField
                      fullWidth
                      required
                      onChange={handleChange}
                      margin="none"
                      value={formik.values.manufacture || ''}
                      error={formik.touched.manufacture && formik.errors.manufacture ? true : false}
                      helperText={formik.touched.manufacture && formik.errors.manufacture}
                      name={'manufacture'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Supplier">
                    <TextField
                      fullWidth
                      required
                      onChange={handleChange}
                      margin="none"
                      value={formik.values.supplier || ''}
                      error={formik.touched.supplier && formik.errors.supplier ? true : false}
                      helperText={formik.touched.supplier && formik.errors.refNo}
                      name={'supplier'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Drug Lic No">
                    <TextField
                      fullWidth
                      required
                      onChange={handleChange}
                      margin="none"
                      value={formik.values.drugLicNo || ''}
                      error={formik.touched.drugLicNo && formik.errors.drugLicNo ? true : false}
                      helperText={formik.touched.drugLicNo && formik.errors.drugLicNo}
                      name={'drugLicNo'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Batch No">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      onChange={handleChange}
                      value={formik.values.batchNo || ''}
                      error={formik.touched.batchNo && formik.errors.batchNo ? true : false}
                      helperText={formik.touched.batchNo && formik.errors.batchNo}
                      name={'batchNo'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Rec qty / Batch Size">
                    <TextField
                      fullWidth
                      required
                      onChange={handleChange}
                      margin="none"
                      value={formik.values.batchSize || ''}
                      error={formik.touched.batchSize && formik.errors.batchSize ? true : false}
                      helperText={formik.touched.batchSize && formik.errors.batchSize}
                      name={'batchSize'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Mfg Date">
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      value={formik.values.mfgDate}
                      error={formik.touched.mfgDate && formik.errors.mfgDate ? true : false}
                      helperText={formik.touched.mfgDate && formik.errors.mfgDate}
                    >
                      <DesktopDatePicker
                        inputFormat="MM/YYYY"
                        views={['year', 'month']}
                        onChange={(value) => {
                          formik.setFieldValue('mfgDate', moment(value).toDate());
                          formik.setFieldTouched('mfgDate', true);
                        }}
                        value={formik.values.mfgDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            margin="none"
                            error={formik.touched.mfgDate && formik.errors.mfgDate ? true : false}
                            helperText={formik.touched.mfgDate && formik.errors.mfgDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </CustomFieldWithLabel>
                </Grid>

                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Best before buy (Exp Date)">
                    <LocalizationProvider
                      dateAdapter={DateAdapter}
                      value={formik.values.expDate}
                      error={formik.touched.expDate && formik.errors.expDate ? true : false}
                      helperText={formik.touched.expDate && formik.errors.expDate}
                    >
                      <DesktopDatePicker
                        inputFormat="MM/YYYY"
                        views={['year', 'month']}
                        onChange={(value) => {
                          formik.setFieldValue('expDate', moment(value).toDate());
                          formik.setFieldTouched('expDate', true);
                        }}
                        value={formik.values.expDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            margin="none"
                            error={formik.touched.expDate && formik.errors.expDate ? true : false}
                            helperText={formik.touched.expDate && formik.errors.expDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}></Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={6}>
                  <CustomFieldWithLabel width={120} label="Sample Qty">
                    <TextField
                      fullWidth
                      required
                      onChange={handleChange}
                      margin="none"
                      value={formik.values.sampleQty || ''}
                      error={formik.touched.sampleQty && formik.errors.sampleQty ? true : false}
                      helperText={formik.touched.sampleQty && formik.errors.sampleQty}
                      name={'sampleQty'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Test Method">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      onChange={handleChange}
                      value={formik.values.testMethod || ''}
                      error={formik.touched.testMethod && formik.errors.testMethod ? true : false}
                      helperText={formik.touched.testMethod && formik.errors.testMethod}
                      name={'testMethod'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}>
                  <CustomFieldWithLabel width={120} label="Label Claim">
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      onChange={handleChange}
                      value={formik.values.labelClaim || ''}
                      error={formik.touched.labelClaim && formik.errors.labelClaim ? true : false}
                      helperText={formik.touched.labelClaim && formik.errors.labelClaim}
                      name={'labelClaim'}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              </Grid> */}

              <Grid item xs={12} style={{ display: 'flex' }} gap={1}>
                <Grid item xs={12}></Grid>
              </Grid>

              {selectedTestGroup?.additionalFields && selectedTestGroup?.additionalFields.length ? (
                <Grid item xs={12}>
                  <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                    Additional Details
                  </Typography>
                  <Divider />
                </Grid>
              ) : null}

              {selectedTestGroup?.additionalFields &&
                selectedTestGroup?.additionalFields.map((field) => {
                  return (
                    <Grid item xs={6}>
                      <CustomFieldWithLabel width={120} label={field.key}>
                        <TextField
                          fullWidth
                          // required
                          value={additionalFields[field.key]}
                          onChange={handleChange}
                          onBlur={formik.handleBlur}
                          // error={formik.touched.rate && formik.errors.rate ? true : false}
                          // helperText={formik.touched.rate && formik.errors.rate}
                          name={`additionalFields.${field.key}`}
                        />
                      </CustomFieldWithLabel>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={1} mt={0.5}>
              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                  Details for laboratory use
                </Typography>
                <Divider />
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel width={110} label="Collection By">
                  {/* @ts-ignore: Unreachable code error */}
                  <TextField
                    fullWidth
                    margin="none"
                    value={formik.values.collectionBy}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.collectionBy && formik.errors.collectionBy ? true : false}
                    helperText={formik.touched.collectionBy && formik.errors.collectionBy}
                    name={'collectionBy'}
                  />
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="HOD" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    value={formik.values.hod}
                    error={formik.touched.hod && formik.errors.hod ? true : false}
                    helperText={formik.touched.hod && formik.errors.hod}
                  >
                    <Select
                      labelId="hod-select-label"
                      id="hod-select-helper"
                      name="hod"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.hod}
                      error={formik.touched.hod && formik.errors.hod ? true : false}
                    >
                      {props.userList
                        .filter((i) => i.role === 'ADMIN' || i.role === 'HOD')
                        .map((item) => (
                          <MenuItem value={item.user._id} key={item._id}>
                            {item.user.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{formik.touched.hod && formik.errors.hod}</FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="Authorized Signature 1" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    value={formik.values.authorizedSignature1}
                    error={formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1 ? true : false}
                    helperText={formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1}
                  >
                    <Select
                      labelId="authorizedSignature1-select-label"
                      id="authorizedSignature1-select-helper"
                      name="authorizedSignature1"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.authorizedSignature1}
                      error={formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1 ? true : false}
                    >
                      {props.userList
                        .filter((i) => i.role === 'ADMIN' || i.role === 'HOD')
                        .map((item) => (
                          <MenuItem value={item.user._id} key={item._id}>
                            {item.user.name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.authorizedSignature1 && formik.errors.authorizedSignature1}
                    </FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={6}>
                <CustomFieldWithLabel label="Reviewed By" width={110}>
                  {/* @ts-ignore: Unreachable code error */}
                  <FormControl
                    margin="dense"
                    fullWidth
                    size="small"
                    required
                    value={formik.values.reviewedBy}
                    error={formik.touched.reviewedBy && formik.errors.reviewedBy ? true : false}
                    helperText={formik.touched.reviewedBy && formik.errors.reviewedBy}
                  >
                    <Select
                      labelId="reviewedBy-select-label"
                      id="reviewedBy-select-helper"
                      name="reviewedBy"
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reviewedBy}
                      error={formik.touched.reviewedBy && formik.errors.reviewedBy ? true : false}
                    >
                      {props.userList.map((item) => (
                        <MenuItem value={item.user._id} key={item._id}>
                          {item.user.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{formik.touched.reviewedBy && formik.errors.reviewedBy}</FormHelperText>
                  </FormControl>
                </CustomFieldWithLabel>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
                  Billing Details
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={'Charges (Rate)'}
                  type="number"
                  value={formik.values.rate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.rate && formik.errors.rate ? true : false}
                  helperText={formik.touched.rate && formik.errors.rate}
                  name={'rate'}
                />
                <TextField
                  fullWidth
                  label={'Discount %'}
                  value={formik.values.discount}
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.discount && formik.errors.discount ? true : false}
                  helperText={formik.touched.discount && formik.errors.discount}
                  name={'discount'}
                />
                <Box display={'flex'} gap={1}>
                  <TextField
                    fullWidth
                    type="number"
                    style={{ flex: 0.5 }}
                    label={'GST %'}
                    value={formik.values.gst}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.gst && formik.errors.gst ? true : false}
                    helperText={formik.touched.gst && formik.errors.gst}
                    name={'gst'}
                  />
                  <Box display={'flex'} style={{ flex: 0.5 }} alignItems={'center'}>
                    <FormControl margin="dense" fullWidth size="small" required>
                      {/* <InputLabel id="department-select-label">Department</InputLabel> */}
                      <Select
                        id="demo-simple-select-helper"
                        name="igst"
                        onChange={(e) => {
                          formik.setFieldValue('igst', e.target.value === 'true');
                        }}
                        value={formik.values.igst ? 'true' : 'false'}
                      >
                        <MenuItem value={'false'}>CGST / SGST</MenuItem>
                        <MenuItem value={'true'}>IGST</MenuItem>
                      </Select>
                    </FormControl>
                    {/* Render checkbox for igst formik field */}
                    {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.igst}
                      onChange={formik.handleChange}
                      name="igst"
                      color="primary"
                      size="small"
                    />
                  }
                  label="IGST"
                /> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="number"
                  label={'Paid Amount'}
                  value={formik.values.paidAmount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.paidAmount && formik.errors.paidAmount ? true : false}
                  helperText={formik.touched.paidAmount && formik.errors.paidAmount}
                  name={'paidAmount'}
                />
                <TextField fullWidth disabled label={'Total'} value={total} />
                <TextField fullWidth disabled label={'Remaining Total'} value={remainingTotal} />
              </Grid>

              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    label="Payment Due Date"
                    inputFormat="DD/MM/YYYY"
                    onChange={(value) => {
                      formik.setFieldValue('paymentDueDate', moment(value).toDate());
                      formik.setFieldTouched('paymentDueDate', true);
                    }}
                    value={formik.values.paymentDueDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={formik.touched.paymentDueDate && formik.errors.paymentDueDate ? true : false}
                        helperText={formik.touched.paymentDueDate && formik.errors.paymentDueDate}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  label={'SAC Code'}
                  value={formik.values.sac}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.sac && formik.errors.sac ? true : false}
                  helperText={formik.touched.sac && formik.errors.sac}
                  name={'sac'}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box display={'flex'} gap={2}>
              <Box flex={1}>
                <CustomFieldWithLabel width={120} label="Parameters">
                  {/* @ts-ignore */}
                  <ReactSelect
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    value={formik.values.parameters.map((i: any) => ({ value: i.name, label: i.name }))}
                    onChange={(value) => {
                      const selectedParameters = selectedMaster?.parameters?.filter((i) =>
                        value.map((i) => i.value).includes(i.name)
                      );
                      formik.setFieldValue('parameters', selectedParameters);
                    }}
                    options={selectedMaster?.parameters?.map((i) => ({
                      value: i.name,
                      label: i.name,
                    }))}
                  />
                </CustomFieldWithLabel>
                <div>{formik.errors.parameters && <div style={{ color: 'red' }}>{formik.errors.parameters}</div>}</div>
              </Box>
              <div>
                <Button variant="contained" onClick={handleSelectAllParameter}>
                  Select All Parameters
                </Button>
              </div>
            </Box>
          </Grid>
        </Grid>

        {parametersForLabelClaim.length > 0 ? (
          <Grid item xs={12} gap={1} mt={2}>
            <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
              Assay Description
            </Typography>
            <Divider />
            <Grid item xs={12} mt={2}>
              <CustomFieldWithLabel width={120} label="Description">
                <TextField
                  fullWidth
                  required
                  margin="none"
                  value={formik.values.description || ''}
                  error={formik.touched.description && formik.errors.description ? true : false}
                  helperText={formik.touched.description && formik.errors.description}
                  onChange={handleChange}
                  name={'description'}
                />
              </CustomFieldWithLabel>
            </Grid>
          </Grid>
        ) : null}

        {parametersForLabelClaim.length > 0 ? (
          <Grid item xs={12} mt={2}>
            <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
              Label Claim
            </Typography>
            <Divider />

            {parametersForLabelClaim.map((parameter: any) => {
              const fieldName = parameter?.name?.replace(/\./g, '');
              return (
                <Grid item xs={6} mt={1}>
                  <CustomFieldWithLabel width={120} label={fieldName || ''}>
                    <TextField
                      fullWidth
                      required
                      margin="none"
                      value={additionalFields[fieldName || '']}
                      onChange={handleChange}
                      error={formik.touched.labelClaim && formik.errors.labelClaim ? true : false}
                      helperText={formik.touched.labelClaim && formik.errors.labelClaim}
                      name={`additionalFields.${fieldName || ''}`}
                    />
                  </CustomFieldWithLabel>
                </Grid>
              );
            })}
          </Grid>
        ) : null}

        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
          <Box>
            <Button disabled={formik.isSubmitting} variant="outlined" onClick={() => navigate('/laboratory/samples')}>
              Back
            </Button>
          </Box>
          <Box ml={1}>
            <Button variant="contained" disabled={formik.isSubmitting} onClick={() => formik.handleSubmit()}>
              {sampleId ? 'Update' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Box>

      <SearchDialog
        isVisible={customerSearchModal}
        title={'Search customer'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={customerSearchQuery}
              autoFocus
              onChange={(e) => setCustomerSearchQuery(e.target.value)}
            />
            {!props.searchCustomerList.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props.searchCustomerList.map((c) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedCustomer(c);
                  setCustomerSearchModal(false);
                  setCustomerSearchQuery('');
                  formik.setFieldValue('customer', c._id);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {c.name}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {c.email}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {c.country} {c.phone}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setCustomerSearchModal(false);
          setCustomerSearchQuery('');
        }}
        hideButtons={true}
      />

      <SearchDialog
        isVisible={testGroupSearchModal}
        title={'Search Test Group'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={testGroupSearchQuery}
              autoFocus
              onChange={(e) => setTestGroupSearchQuery(e.target.value)}
            />
            {!props.masterList.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props.testGroupsList.map((m) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedTestGroup(m);
                  setTestGroupSearchModal(false);
                  setTestGroupSearchQuery('');
                  formik.setFieldValue('testGroup', m._id);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {m.name}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {m.prefix}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setTestGroupSearchModal(false);
          setTestGroupSearchQuery('');
        }}
        hideButtons={true}
      />

      <SearchDialog
        isVisible={masterSearchModal}
        title={'Search Master'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={masterSearchQuery}
              autoFocus
              onChange={(e) => setMasterSearchQuery(e.target.value)}
            />
            {!props.masterList.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props.masterList.map((m) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedMaster(m);
                  setMasterSearchModal(false);
                  setMasterSearchQuery('');
                  formik.setFieldValue('name', m.sampleName);
                  formik.setFieldValue('marking', m.marking);
                  formik.setFieldValue('name', m.sampleName);
                  formik.setFieldValue('genericName', m.genericName);
                  formik.setFieldValue('testMethod', m.testingMethod);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {m.sampleName}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {m.genericName}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {m.masterId}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setMasterSearchModal(false);
          setMasterSearchQuery('');
        }}
        hideButtons={true}
      />
      <SearchDialog
        isVisible={referenceSearchModal}
        title={'Search Reference'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={referenceSearchQuery}
              autoFocus
              onChange={(e) => setReferenceSearchQuery(e.target.value)}
            />
            {!props?.referenceList?.length && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="subtitle1" fontSize={14} pl={1}>
                  Not found
                </Typography>
              </Box>
            )}
            {props?.referenceList?.map((m) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedReference(m);
                  setReferenceSearchModal(false);
                  setReferenceSearchQuery('');
                  formik.setFieldValue('reference', m._id);
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {m.name}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setReferenceSearchModal(false);
          setReferenceSearchQuery('');
        }}
        hideButtons={true}
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
  userList: state.laboratory.users,
  department: state.department.list,
  searchCustomerList: state.laboratory.searchCustomer,
  masterList: state.laboratory.masters,
  testGroupsList: state.laboratory.testGroups,
  referenceList: state.laboratory.reference,
  sampleDetails: state.laboratory.sampleDetails,
  priceList: state.laboratory.priceListDetails,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        searchCustomer,
        searchMasterList,
        searchTestGroupList,
        getUserList,
        createSample,
        searchReference,
        getSampleById,
        updateSample,
        getPriceListByMasterAndCustomer,
      },
      dispatch
    ),
  };
}

export const PersistSamplePharma = connect(mapStateToProps, mapDispatchToProps)(_PersistSamplePharma);
