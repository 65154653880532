import A4 from 'PdfPages/pages/A4';
import { useEffect, useState } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { searchSample } from 'actions/LaboratoryActions';
import './print.css';
import { ICustomer, ISample } from 'exactt-types/lib';
import moment from 'moment';

interface SampleAllPrintProps {
  setDisableScroll: (value: boolean) => void;
  searchSample: (
    laboratoryId: string,
    params: {
      page?: number;
      size?: number;
      query?: string;
      startDate?: string;
      endDate?: string;
      testGroup?: string;
      customer?: string;
      status?: string;
    }
  ) => Promise<any>;
  sampleList: ISample[] | [];
  laboratory: ILaboratoryDetails | null;
}

export function _InwardPharmaPrint(props: SampleAllPrintProps) {
  const [samples, setSamples] = useState<ISample[] | []>([]);

  let query = useQuery();

  const onFocus = async () => {
    const filteredSample = await props.searchSample(props.laboratory?.details?._id || '', {
      query: '',
      startDate: query.get('from') as string,
      endDate: query.get('to') as string,
      testGroup: (query.get('testGroup') as string) === 'undefined' ? undefined : (query.get('testGroup') as string),
    });
    setSamples(filteredSample?.data?.samples);
  };

  const startDate = moment(query.get('from')).format('DD/MM/YYYY');
  const endDate = moment(query.get('to')).format('DD/MM/YYYY');

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();
    // eslint-disable-next-line
  }, []);

  console.log('samples -> ', samples);

  return (
    <A4
      header={
        <>
          {props.laboratory && (
            <>
              <h2 style={{ marginBottom: 2 }}>{props?.laboratory?.details?.name || 'No name'}</h2>
              <div>
                Inward and Outward report for the period from {''}
                {startDate} to {endDate}
              </div>
              {(query.get('testGroup') as string) && (query.get('testGroup') as string) !== 'undefined' ? (
                <div>
                  Group Name:
                  {/* @ts-ignore */}
                  {samples?.[0]?.testGroup?.name || 'N/A'}
                </div>
              ) : null}
            </>
          )}
        </>
      }
      landscape={true}
      body={
        <>
          <table style={{ width: '100%', marginTop: 5, borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th colSpan={11}></th>
              </tr>
              <tr>
                <th style={{ textAlign: 'left', width: '5%', paddingRight: '5px', border: '1px solid black' }}>
                  Sr. No
                </th>
                <th style={{ textAlign: 'left', width: '10%', paddingRight: '10px', border: '1px solid black' }}>
                  Date
                </th>
                <th style={{ textAlign: 'left', width: '10%', paddingRight: '5px', border: '1px solid black' }}>
                  Commodity (Sample Name)
                </th>
                <th style={{ textAlign: 'left', width: '10%', paddingRight: '5px', border: '1px solid black' }}>
                  Party Name
                </th>
                <th style={{ textAlign: 'left', width: '10%', paddingRight: '5px', border: '1px solid black' }}>
                  Certificate No.
                </th>
                <th style={{ textAlign: 'left', width: '15%', paddingRight: '5px', border: '1px solid black' }}>
                  Test Name
                </th>
              </tr>
            </thead>
            <tbody>
              {samples?.map((sample, index) => {
                return (
                  <tr key={index}>
                    <td style={{ border: '1px solid black', padding: '5px' }}>{index + 1}</td>
                    <td style={{ border: '1px solid black', padding: '5px' }}>
                      {moment(sample?.retainDate).format('DD/MM/YYYY') || 'N/A'}
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px' }}>{sample?.name || 'N/A'}</td>
                    <td style={{ border: '1px solid black', padding: '5px' }}>
                      {(sample?.customer as ICustomer)?.name || 'N/A'}
                    </td>
                    <td style={{ border: '1px solid black', padding: '5px' }}>{sample?.sampleId || 'N/A'}</td>
                    <td style={{ border: '1px solid black', padding: '5px' }}>
                      {typeof sample?.collectionBy === 'string'
                        ? sample?.parameters?.map((i: any) => i.name)?.join(',') || 'N/A'
                        : 'N/A'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      }
      footer={
        <div>
          <div id="page-number"></div>
        </div>
      }
    />
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleList: state.laboratory.samples,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchSample }, dispatch),
  };
}

export const InwardPharmaPrint = connect(mapStateToProps, mapDispatchToProps)(_InwardPharmaPrint);
