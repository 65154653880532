import { Box, Paper } from '@mui/material';
import { SideDrawer } from 'components/SideDrawer';
import { Dashboard } from 'pages/Dashboard';
import { CreateLaboratory } from 'pages/CreateLaboratory';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { LaboratoryNotFound } from 'pages/LaboratoryNotFound';
import { User } from 'pages/User';
import { Customer } from 'pages/Customer';
import { PersistCustomer } from 'pages/Customer/PersistCustomer';
import { IReducer } from 'Reducers/IReducer';
import { connect } from 'react-redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { CustomerDetails } from 'pages/Customer/CustomerDetails';
import { Master } from 'pages/Master/Master';
import { PersistMaster } from 'pages/Master/PersistMater';
import { Sample } from 'pages/Sample';
import { PersistSampleFoodAndWater } from 'pages/Sample/PersistSampleFoodAndWater';
import { TestGroup } from 'pages/TestGroup';
import { PersistTestGroup } from 'pages/TestGroup/PersistTestGroup';
import { SampleDetails } from 'pages/Sample/SampleDetails';
import { SampleResult } from 'pages/Sample/SampleResult';
import { PersistReference } from 'pages/Reference/PersistReference';
import { Reference } from 'pages/Reference/Reference';
import { Department } from 'pages/Department';
import { PersistDepartment } from 'pages/Department/PersistDepartment';
import { SampleResultPrint } from 'pages/SampleResultPrint';
import { useState } from 'react';
import { Audit } from 'pages/Audit';
import { SampleJobSheetPrint } from 'pages/SampleJobSheetPrint';
import { SampleJobRequestPrint } from 'pages/SampleJobRequest';
import { SampleJobAllocationPrint } from 'pages/SampleJobAllocationPrint';
import { ResultTemplate } from 'pages/ResultTemplate';
import MainNav from './MainNav';
import { PersistTestResultTemplate } from 'pages/ResultTemplate/PersistResultTemplate';
import { SampleResultPrintV2 } from 'pages/SampleResultPrintV2';
import { Register } from 'pages/Register';
import { InwardPrint } from 'pages/InwardPrint';
import { OutwardPrint } from 'pages/OutwardPrint';
import { RetainedPrint } from 'pages/Retained';
import { SampleLabel } from 'pages/SampleLabel';
import InvoicePrint from 'pages/InvoicePrintTemplate';
import { PersistSampleKSZ } from 'pages/Sample/PersistSampleKSZ/PersistSampleKSZ';
import { PersistSamplePharma } from 'pages/Sample/PersistSamplePharma';
import { CreatePriceList } from 'pages/PriceList/CreatePriceList';
import { PriceList } from 'pages/PriceList/PriceList';
import { InwardPharmaPrint } from 'pages/InwardPharmaPrint';
import { SampleJobSheetPrintPharma } from 'pages/SampleJobSheetPrintPharma';

interface IAuthNavigation {
  laboratory: null | ILaboratoryDetails;
}

export function _AuthNavigation(props: IAuthNavigation) {
  const [disableScroll, setDisableScroll] = useState(false);
  const { laboratory } = props;
  return (
    <BrowserRouter>
      <>
        {!disableScroll && <MainNav />}

        <Box sx={{ display: 'flex', height: '100%' }}>
          <SideDrawer />
          <Paper
            sx={!disableScroll ? { width: '100%', overflow: 'scroll', paddingTop: 6 } : undefined}
            elevation={!disableScroll ? 2 : 0}
          >
            <Routes>
              <Route path="/">
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="/laboratory">
                {!laboratory && <Route path="create" element={<CreateLaboratory />} />}
                {!laboratory && <Route path="notfound" element={<LaboratoryNotFound />} />}
                {laboratory?.access.accessList.laboratorySetting && <Route path="users" element={<User />} />}
                {laboratory?.access.accessList.customer && <Route path="customers" element={<Customer />} />}
                {laboratory?.access.accessList.customer && (
                  <Route path="customers/persist" element={<PersistCustomer />} />
                )}
                {laboratory?.access.accessList.customer && (
                  <Route path="customers/:customerId" element={<CustomerDetails />} />
                )}
                {laboratory?.access.accessList.master && <Route path="masters" element={<Master />} />}
                {laboratory?.access.accessList.master && <Route path="masters/persist" element={<PersistMaster />} />}
                {laboratory?.access.accessList.samples && <Route path="samples" element={<Sample />} />}

                <Route path="samples/persistSampleFood-Water" element={<PersistSampleFoodAndWater />} />

                <Route path="samples/persist-pharma-sample" element={<PersistSamplePharma />} />

                <Route path="samples/KSZ" element={<PersistSampleKSZ />} />

                {laboratory?.access.accessList.samples && (
                  <Route path="samples/sampleDetails" element={<SampleDetails />} />
                )}
                {laboratory?.access.accessList.samples && <Route path="samples/result" element={<SampleResult />} />}
                {laboratory?.access.accessList.samples && <Route path="testgroups" element={<TestGroup />} />}
                {laboratory?.access.accessList.samples && <Route path="register" element={<Register />} />}
                {laboratory?.access.accessList.samples && (
                  <Route path="testgroups/persist" element={<PersistTestGroup />} />
                )}
                <Route
                  path="samples/print/result"
                  element={<SampleResultPrint setDisableScroll={setDisableScroll} />}
                />
                <Route
                  path="samples/print/resultV2"
                  element={<SampleResultPrintV2 setDisableScroll={setDisableScroll} />}
                />
                <Route
                  path="samples/print/jobAllotment"
                  element={<SampleJobAllocationPrint setDisableScroll={setDisableScroll} />}
                />
                <Route path="print/inward" element={<InwardPrint setDisableScroll={setDisableScroll} />} />
                <Route path="print/inwardPharma" element={<InwardPharmaPrint setDisableScroll={setDisableScroll} />} />
                <Route path="print/outward" element={<OutwardPrint setDisableScroll={setDisableScroll} />} />
                <Route path="print/retained" element={<RetainedPrint setDisableScroll={setDisableScroll} />} />
                <Route path="print/invoice" element={<InvoicePrint setDisableScroll={setDisableScroll} />} />
                {/* <Route
                  path="samples/print/invoice"
                  element={<SampleInvoicePrint setDisableScroll={setDisableScroll} />}
                /> */}
                <Route path="samples/print/sampleLabel" element={<SampleLabel setDisableScroll={setDisableScroll} />} />
                <Route
                  path="samples/print/jobSheet"
                  element={<SampleJobSheetPrint setDisableScroll={setDisableScroll} />}
                />
                <Route
                  path="samples/print/jobSheetPharma"
                  element={<SampleJobSheetPrintPharma setDisableScroll={setDisableScroll} />}
                />
                <Route
                  path="samples/print/testReq"
                  element={<SampleJobRequestPrint setDisableScroll={setDisableScroll} />}
                />
                <Route path="references" element={<Reference />} />
                <Route path="references/persist" element={<PersistReference />} />
                <Route path="departments" element={<Department />} />
                <Route path="departments/persist" element={<PersistDepartment />} />
                <Route path="audits" element={<Audit />} />
                <Route path="resultTemplate" element={<ResultTemplate />} />
                <Route path="resultTemplate/persist" element={<PersistTestResultTemplate />} />
                <Route path="price-list/persist" element={<CreatePriceList />} />
                <Route path="price-list" element={<PriceList />} />
              </Route>
            </Routes>
          </Paper>
        </Box>
      </>
    </BrowserRouter>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
});

const AuthNavigation = connect(mapStateToProps)(_AuthNavigation);

export default AuthNavigation;
